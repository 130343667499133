import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'icon-buyer',
    templateUrl: './icon-buyer.component.svg',
    styleUrls: ['./icon-buyer.component.scss'],
})
export class IconBuyerComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
