<div class="landing-page">
    <ct-header [viewNav]="true" [signIn]="signIn" [signUp]="signUp">
        <div class="header-content">
            <div class="row">
                <div class="col md-12 lg-8 xl-6">
                    <div class="header-content-left">
                        <h1>
                            <span>Ahora podés invertir</span>
                            <strong> en Real Estate</strong>
                        </h1>
                        <p>
                            Invertí en las mejores propiedades y desarrollos a
                            través del
                            <strong>Crowdfunding Inmobiliario</strong> desde
                            montos mínimos.
                        </p>
                        <a class="btn lg" [href]="signUp" target="_top">
                            Abrir Cuenta
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </ct-header>
    <about-ci [buttonCallToAction]="true"></about-ci>
    <why-real-estate></why-real-estate>
    <investments id="investments"></investments>
    <invest-in-real-estate
        [signIn]="signIn"
        [signUp]="signUp"
    ></invest-in-real-estate>
    <as-investment></as-investment>
    <faq [faq]="faq"></faq>
    <!--<legal></legal>-->
    <!--<from-your-mobile [callToAction]="signIn"></from-your-mobile>-->
    <ct-footer></ct-footer>
    <!--<partners></partners>-->
</div>
