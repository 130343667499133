import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'icon-user',
    templateUrl: './icon-user.component.html',
    styleUrls: ['./icon-user.component.scss'],
})
export class IconUserComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
