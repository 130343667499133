<div class="investment">
    <h1>Proyectos de Inversión</h1>
    <div class="investments-content">
        <div class="row justify-content-center">
            <ng-container *ngIf="data && !error">
                <ng-container *ngFor="let obj of data; let i = index">
                    <div class="col sm-12 md-6 lg-4">
                        <project-card
                            [data]="obj"
                            (moreInfo)="moreInfo($event)"
                        ></project-card>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
