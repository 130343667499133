export const WhyRealEstateData = [
    {
        title: 'Reserva de Valor',
        content:
            'Inversión en bienes inmuebles valuados en dólares que resisten la inflación y devualuación.',
        icon: `<icon-value></icon-value>`,
    },
    {
        title: 'Rentable',
        content:
            'Rentabilidad obtenida por la revalorización del activo y su renta. Según tipo de proyecto',
        icon: `<icon-investment></icon-investment>`,
    },
    {
        title: 'Ser Dueño',
        content: 'Ser el propietario de un porcentaje de un bien inmueble.',
        icon: `<icon-owner></icon-owner>`,
    },
    {
        title: 'Diversificación',
        content:
            'Posibilidad de invertir en múltiples proyectos inmobiliarios desde montos muy bajos.',
        icon: `<icon-diversifity></icon-diversifity>`,
    },
];
