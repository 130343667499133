<svg
    width="19"
    height="19"
    [class]="className"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="M18.854 2.167l-7.894 16.21c-.26.533-.89.775-1.431.523a1.1 1.1 0 01-.601-.76l-1.062-6.037a1.19 1.19 0 00-.954-.966L.894 10.075a1.077 1.077 0 01-.786-.589 1.088 1.088 0 01.496-1.441l16.217-7.9a1.53 1.53 0 012.026.713c.209.43.197.9.007 1.309z"
        stroke="#FFF"
        fill="none"
    />
</svg>
