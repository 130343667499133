import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'navbar-left',
    templateUrl: './navbar-left.component.html',
    styleUrls: ['./navbar-left.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NavbarLeftComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
