<svg
    id="diversifity"
    [class]="className"
    data-name="diversifity"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
>
    <g>
        <g>
            <path
                class="diversifity-icon"
                d="M103.64,70.76,80.63,17l5.9-2.77A148.37,148.37,0,0,1,147.23.13L154.09,0V58.34l-6.55.17A90.58,90.58,0,0,0,110,67.66Zm-5.44-47,12.36,28.89a103.66,103.66,0,0,1,30.06-7.25V13.89A134.54,134.54,0,0,0,98.2,23.77Z"
            />
            <path
                class="diversifity-icon"
                d="M247.73,263.83,205.45,223.2l5.43-4.84A91.7,91.7,0,0,0,241.58,150c0-1.39,0-2.77-.1-4.15l-.32-7h58.49l.24,7.56c0,1.2.08,2.4.08,3.62A150.51,150.51,0,0,1,252.4,259.46ZM225.1,223.4l22.65,21.75a137,137,0,0,0,38.73-92.83H255A105.13,105.13,0,0,1,225.1,223.4Z"
            />
            <g>
                <path
                    class="diversifity-icon"
                    d="M194.73,8.39,171.61,30.62V46.2l35.34-34Q201,10,194.73,8.39Z"
                />
                <path
                    class="diversifity-icon"
                    d="M180.16,5.29l-6.26-1-2.29,2.21V22.09l16-15.39C185.15,6.17,182.67,5.69,180.16,5.29Z"
                />
                <path
                    class="diversifity-icon"
                    d="M215.34,15.54,171.61,57.61v.32l5.57,1.31c2.34.55,4.63,1.23,6.89,2l42-40.43Q220.86,18,215.34,15.54Z"
                />
                <path
                    class="diversifity-icon"
                    d="M233.46,25l-41,39.39a86.63,86.63,0,0,1,10.44,5.54L243,31.4Q238.38,28,233.46,25Z"
                />
                <path
                    class="diversifity-icon"
                    d="M249.5,36.48,209.8,74.67a85.9,85.9,0,0,1,8.49,7.42L257.9,44Q253.87,40.08,249.5,36.48Z"
                />
                <path
                    class="diversifity-icon"
                    d="M263.62,49.91,223.78,88.23a80.93,80.93,0,0,1,6.53,9.3l40.55-39Q267.42,54.08,263.62,49.91Z"
                />
                <path
                    class="diversifity-icon"
                    d="M275.9,65.5l-41.47,39.89a78.36,78.36,0,0,1,3.15,8l1.11,3.44L281.9,75.31C280.05,72,278,68.68,275.9,65.5Z"
                />
                <path
                    class="diversifity-icon"
                    d="M286,83.29l-36.44,35.05h16.19l24.84-23.89C289.21,90.65,287.67,86.93,286,83.29Z"
                />
                <path
                    class="diversifity-icon"
                    d="M295,110c-.5-2.33-1.09-4.63-1.72-6.91l-15.82,15.22h16.19l2.6-2.51Z"
                />
            </g>
            <path
                class="diversifity-icon"
                d="M150,300C67.31,300,0,232.72,0,150A149,149,0,0,1,58.59,31.25L65.36,26l24.57,54.8-3.41,3.28A91.52,91.52,0,0,0,183,235.42l4.12-1.6,42.9,43.27-7.81,4.31A149.83,149.83,0,0,1,150,300ZM60.16,47.38A135.5,135.5,0,0,0,13.5,150c0,75.26,61.23,136.5,136.5,136.5a136.43,136.43,0,0,0,57.71-12.84l-24-24.19A104.12,104.12,0,0,1,150,255.09,105.18,105.18,0,0,1,44.94,150,104,104,0,0,1,73.81,77.8Z"
            />
        </g>
        <path
            class="diversifity-icon"
            d="M155.53,185.24c10.7-.73,18.93-4.67,18.93-15.36s-8.23-14.52-18.93-17.83l-7-2c-14.26-4.06-27.17-9.22-27.17-25,0-12.79,10-22.75,26.8-23.73V92.53h7.63v9.1c13.64,1.36,20.65,8.24,25.2,15.5l-7.75,5.77c-4.3-6.88-8.73-11.31-17.7-12.41l-7-.25c-11.68.61-16.84,6.64-16.84,14,0,9.1,7.13,12.78,16.84,15.86l7,2.09c15.12,4.18,29.26,9.47,29.26,26.8,0,13.77-10.45,23.73-29,25v10.7h-7.63V193.85c-16.11-1.23-25-10.08-29.39-17.7l7.87-5.17c5.29,7.87,11.32,13.28,21.89,14.26Z"
        />
        <path
            class="diversifity-icon"
            d="M146.73,274.31c-1.79,0-3.58,0-5.35-.12l.4-9c1.64.07,3.29.1,4.95.1,1.93,0,3.9-.05,5.85-.16l.49,9C151,274.25,148.82,274.31,146.73,274.31Zm-16.14-.44c-3.82-.64-7.66-1.49-11.42-2.51l2.36-8.67A106.78,106.78,0,0,0,132.08,265Zm-21.48-5.43a116.36,116.36,0,0,1-10.74-4.6l3.94-8.07c3.23,1.58,6.57,3,9.93,4.26ZM88,258.63c-3.27-2.07-6.48-4.33-9.55-6.72l5.51-7.09c2.84,2.21,5.82,4.3,8.85,6.22Zm-17.42-12.2c-2.89-2.59-5.68-5.35-8.31-8.21l6.62-6.08c2.43,2.65,5,5.21,7.69,7.61ZM55.42,231.26c-2.46-3-4.8-6.13-7-9.35l7.44-5c2,3,4.2,5.91,6.48,8.68ZM43.56,214.89c-2-3.32-3.84-6.78-5.51-10.27l8.1-3.88c1.56,3.25,3.28,6.46,5.12,9.54Zm-9.37-18.35c-1.47-3.59-2.8-7.28-3.94-11l8.58-2.64c1.06,3.43,2.3,6.86,3.67,10.19Zm-6.93-21.15c-.56-3.82-1-7.72-1.16-11.6l9-.49c.2,3.61.56,7.23,1.08,10.78Zm-1.54-23c0-3.86.08-7.78.38-11.64l8.95.69c-.28,3.6-.39,7.25-.35,10.84Zm9.83-22.27-8.82-1.68c.72-3.82,1.63-7.63,2.7-11.34l8.63,2.47C37.07,123,36.23,126.52,35.55,130.07ZM41.15,110,32.66,107c1.28-3.66,2.73-7.31,4.34-10.83l8.17,3.71C43.69,103.17,42.33,106.55,41.15,110Z"
        />
    </g>
</svg>
