<svg
    class="icon-acquisition"
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
>
    <g
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
        fill-rule="evenodd"
    >
        <g class="ng-scope">
            <polyline points="16 8 11 8 11 31 25 31 25 16"></polyline>
            <circle cx="25" cy="7" r="6"></circle>
            <path d="M25,5 L25,9"></path>
            <path d="M23,7 L27,7"></path>
            <path d="M2,31 L30,31"></path>
            <polyline points="16 31 16 23 20 23 20 31"></polyline>
            <polyline points="11 18 6 18 6 30"></polyline>
            <path d="M15,18 L21,18"></path>
            <path d="M15,14 L19,14"></path>
        </g>
    </g>
</svg>
