<svg
    class="icon-tools"
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
>
    <g
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
        fill-rule="evenodd"
    >
        <g
            ng-if="update.eventType !== 'AcquisitionEvent' &amp;&amp; update.eventType !== 'ExitEvent'"
            class="ng-scope"
        >
            <path
                d="M19.5,16.5 L23.410717,20.410717 C24.0548852,20.1459869 24.7603941,20 25.5,20 C28.5375661,20 31,22.4624339 31,25.5 C31,26.1868237 30.8741064,26.8442433 30.6441365,27.4504417 L28.5,25 L25,25 L25,28 L27.589283,30.589283 C26.9451148,30.8540131 26.2396059,31 25.5,31 C22.4624339,31 20,28.5375661 20,25.5 C20,24.7603941 20.1459869,24.0548852 20.410717,23.410717 L16.5,19.5"
            ></path>
            <path
                d="M12.5,15.5 L8.58928296,11.589283 C7.94511481,11.8540131 7.23960594,12 6.5,12 C3.46243388,12 1,9.53756612 1,6.5 C1,5.81317633 1.12589359,5.15575668 1.35586351,4.5495583 L3.5,7 L7,7 L7,4 L4.41071704,1.41071704 C5.05488519,1.1459869 5.76039406,1 6.5,1 C9.53756612,1 12,3.46243388 12,6.5 C12,7.23960594 11.8540131,7.94511481 11.589283,8.58928296 L15.5,12.5"
            ></path>
            <polygon points="29 1 31 3 28 7 25 4"></polygon>
            <path d="M25,7 L12,20"></path>
            <path
                d="M13,23 L5.58578644,30.4142136 C5.21071368,30.7892863 4.70200585,31 4.17157288,31 L3,31 C1.8954305,31 1,30.1045695 1,29 L1,27.8284271 C1,27.2979941 1.21071368,26.7892863 1.58578644,26.4142136 L9,19"
            ></path>
            <path d="M8.5,17.5 L14.5,23.5"></path>
        </g>
    </g>
</svg>
