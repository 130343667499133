<div class="partners">
    <a href="http://www.cnv.gov.ar/sitioweb/" target="_blank" rel="noreferrer">
        <img
            [src]="logoCNV"
            alt="Logo y link de la Comisión Nacional de Valores"
        />
    </a>
    <a href="http://www.bcra.gov.ar/" target="_blank" rel="noreferrer">
        <img [src]="logoBCNA" alt="Banco Central de la República Argentina" />
    </a>
    <a
        href="https://www.byma.com.ar/en/homepage/"
        target="_blank"
        rel="noreferrer"
    >
        <img [src]="logoBYMA" alt="Bolsas y Mercados Argentinos" />
    </a>
    <a href="http://www.mae.com.ar/" target="_blank" rel="noreferrer">
        <img [src]="logoMAE" alt="Mercado Abierto Electrónico" />
    </a>
    <a href="http://www.rofex.com.ar/" target="_blank" rel="noreferrer">
        <img [src]="logoROFEX" alt="Logo y link de Rofex" />
    </a>
    <a href="https://www.cafci.org.ar/" target="_blank" rel="noreferrer">
        <img
            [src]="logoCAFCI"
            alt="Cámara Argentina de Fondos Comunes de Inversión"
        />
    </a>
    <a
        href="http://www.cadane.com.ar/home.php?v=1"
        target="_blank"
        rel="noreferrer"
    >
        <img
            [src]="logoCADANE"
            alt="Logo y link de La Cámara de Agentes de Bolsa"
        />
    </a>
    <a
        href="https://www.thomsonreuters.com.ar/es/tienda.html?gclid=CjwKCAjw2MTbBRASEiwAdYIpsbnHLGX5zlcFg2QVFrVKWiANuDPggBGB1FdxPw7_AXeVCm0PrIjZWxoCVkwQAvD_BwE"
        target="_blank"
        rel="noreferrer"
    >
        <img [src]="logoTHOMSONREUTERS" alt="Thomson Reuters" />
    </a>
    <a
        href="https://www.argentina.gob.ar/sid-sistema-de-identidad-digital"
        target="_blank"
        rel="noreferrer"
    >
        <img
            [src]="logoSID"
            alt="Logo y link de SID - Sistema de Identidad Digital"
        />
    </a>
</div>
