import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'icon-bars',
    templateUrl: './icon-bars.component.html',
    styleUrls: ['./icon-bars.component.scss'],
})
export class IconBarsComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
