<svg
    id="doughnut-icon"
    class="doughnut-icon"
    [class]="className"
    data-name="doughnut-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
>
    <defs>
        <clipPath id="clip-path">
            <ellipse
                class="doughnut-icon-1"
                cx="150"
                cy="155.3"
                rx="150"
                ry="144.7"
            />
        </clipPath>
    </defs>
    <title>fondos mixtos blanco</title>
    <g class="doughnut-icon-2">
        <path
            class="doughnut-icon-3"
            d="M44,76.92l47.7,43.36a6.57,6.57,0,0,1,1.56,7.5,64.29,64.29,0,0,0,75.3,87.75,6.46,6.46,0,0,1,7.25,3.22l28.71,52.52a6.55,6.55,0,0,1-3.74,9.36C148,297.92,90,283.63,52.9,243.89c-41-44-48.78-111.11-19-165.27A6.55,6.55,0,0,1,44,76.92Z"
        />
        <path
            class="doughnut-icon-3"
            d="M231.84,264.11l-32.58-52.87a9.59,9.59,0,0,1,1.48-11.85,64.07,64.07,0,0,0,12.07-75.75.53.53,0,0,1,.09-.63l46-49.85a5.46,5.46,0,0,1,8.42.47C278,88.21,303,129,292.08,181.25c-10.45,49.86-46.67,76.43-57.38,83.5A2,2,0,0,1,231.84,264.11Z"
        />
        <path
            class="doughnut-icon-3"
            d="M241.7,54.69C225.91,70,210,86.82,194.25,102.15c-1,1-8.54-3-11-4q-2.59-1.12-5.15-2.17A3.47,3.47,0,0,1,176,92.39q3.41-33.33,6.79-66.65a3.47,3.47,0,0,1,4.18-3,124.74,124.74,0,0,1,54.58,26.86A3.46,3.46,0,0,1,241.7,54.69Z"
        />
        <path
            class="doughnut-icon-4"
            d="M153.35,24.8q-2.78,32-5.57,64a4.9,4.9,0,0,1-4.05,4.37c-3.61.64-7.45,1.48-11.48,2.57a137.85,137.85,0,0,0-16.36,5.55,5,5,0,0,1-5.18-.86L61.3,57.1a4.81,4.81,0,0,1,0-7.27,122.92,122.92,0,0,1,41.07-23.75,124.62,124.62,0,0,1,46.31-6.58A4.89,4.89,0,0,1,153.35,24.8Z"
        />
    </g>
</svg>
