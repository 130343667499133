<svg
    class="icon-sale"
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
>
    <g
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
        fill-rule="evenodd"
    >
        <g class="ng-scope">
            <path
                d="M6.60427755,19.0692571 C10.1886694,18.4867265 13.8228245,18.7129469 14.9677714,19.0488816 L22.191151,21.1685878 C24.251298,21.7729306 23.3389714,24.9001796 21.2794776,24.2953143 L13.6905143,22.0682449"
            ></path>
            <path
                d="M23.2228571,22.3145581 L28.8509388,19.5290657 C30.8930612,18.5184153 32.1780245,21.7676129 30.2445714,22.7243771 L22.6020571,26.5067647 C21.2958041,27.1532576 16.1069714,27.622635 10.3476245,24.827358 C9.35797551,24.3472033 7.67699592,24.9708655 6.60440816,25.2696504"
            ></path>
            <rect x="1" y="18" width="5" height="10"></rect>
            <circle cx="20" cy="10" r="7"></circle>
            <path
                d="M18.2622202,11.9770729 C18.7459307,12.4051239 19.4920054,12.7265927 20.0304066,12.7265927 C20.8919991,12.7265927 21.5903811,12.1058327 21.5903811,11.340164 C21.5903811,10.5744954 20.8446293,10.1956444 20.0304066,9.95373535 C19.1864701,9.68491159 18.4704321,9.33297531 18.4704321,8.56730667 C18.4704321,7.80163802 19.168814,7.18087798 20.0304066,7.18087798 C20.6105793,7.18087798 21.1166828,7.46229781 21.3855066,7.8799059"
            ></path>
            <path d="M20.0269165,6.5 L20.0269165,7.0123291"></path>
            <path d="M20.0269165,13.02771 L20.0269165,13.5"></path>
        </g>
    </g>
</svg>
