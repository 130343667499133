<svg
    id="owner-icon"
    [class]="className"
    data-name="owner-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
>
    <g>
        <g>
            <path class="owner-icon" d="M53,262.75H41.52v-11.5H53Z" />
            <path
                class="owner-icon"
                d="M171.44,294.46a30.3,30.3,0,0,1-9.75-1.62c-20.83-7.1-60.61-22.93-76.75-29.41A20.15,20.15,0,0,0,77.46,262H70.73V288H0V154.82H70.73V179H94.59c1.1,0,27,.84,50.51,23.36h71.39v.28a19.45,19.45,0,0,1,16.26,19l37-23.92a18.07,18.07,0,0,1,12-4.58,18.22,18.22,0,0,1,6.56,35.23L187.1,290.05A30.1,30.1,0,0,1,171.44,294.46ZM70.73,252h6.73a29.71,29.71,0,0,1,11.19,2.16c12.58,5,55.24,22.05,76.26,29.21a20.11,20.11,0,0,0,17-1.85l105.91-64.62a8.21,8.21,0,0,0,1.88-7.88,8.33,8.33,0,0,0-7.31-5.93,7.77,7.77,0,0,0-3.29.46l-53,34.24a23.43,23.43,0,0,1-10.65,3.61c-.45.05-.95.08-1.45.1h-.15a3.25,3.25,0,0,1-.44,0H146.1a11.89,11.89,0,0,0-3.8.55c-3.33,1.11-3.78,2.84-3.78,4.39V248h-10v-1.49c0-6.57,3.86-11.62,10.6-13.87a22.08,22.08,0,0,1,7-1.06h66.75a9.81,9.81,0,0,0,9.81-8.16,9.62,9.62,0,0,0-9.5-11.05H141l-1.46-1.47C118.61,190,94.7,189,94.46,189H70.73ZM10,278.06H60.74V164.8H10Z"
            />
        </g>
        <path
            class="owner-icon"
            d="M245.18,76.25a5.79,5.79,0,0,0-4.4,5.71v81.68H137.94V81.79A5.7,5.7,0,0,0,131,76.25a5.79,5.79,0,0,0-4.4,5.71v87.36a5.67,5.67,0,0,0,5.67,5.67H246.45a5.67,5.67,0,0,0,5.67-5.67V81.79A5.69,5.69,0,0,0,245.18,76.25Z"
        />
        <path
            class="owner-icon"
            d="M275.86,81.81a5.88,5.88,0,0,0-1.87-3L192.53,7A5.68,5.68,0,0,0,185,7L104.59,77.81a5.67,5.67,0,0,0,7.51,8.51l74.59-65.71a3.13,3.13,0,0,1,4.15,0l75.77,66.87a5.67,5.67,0,0,0,9.25-5.67Z"
        />
        <g>
            <path
                class="owner-icon"
                d="M209.13,152.91a16.41,16.41,0,1,1,14.13-14.13A16.43,16.43,0,0,1,209.13,152.91Zm-.75-23.2a7.07,7.07,0,1,0,5.54,5.55A7.08,7.08,0,0,0,208.38,129.71Z"
            />
            <path
                class="owner-icon"
                d="M176.23,113a16.41,16.41,0,1,1,14.13-14.14A16.43,16.43,0,0,1,176.23,113Zm-.75-23.2A7.07,7.07,0,1,0,181,95.32,7.07,7.07,0,0,0,175.48,89.77Z"
            />
            <rect
                class="owner-icon"
                x="184.59"
                y="70.39"
                width="10.16"
                height="94.29"
                rx="1.02"
                transform="translate(138.66 -99.69) rotate(45)"
            />
        </g>
    </g>
</svg>
