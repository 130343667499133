import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'legal',
    templateUrl: './legal.component.html',
    styleUrls: ['./legal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LegalComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
