<div class="invest-in-real-estate">
    <div class="invest-in-real-estate-content">
        <h2>
            Invertí en el mercado más seguro del mundo. Invertí en Real Estate.
        </h2>
        <a
            [href]="signUp"
            target="_top"
            class="btn lg outline btn-white-opacity"
        >
            Abrí tu Cuenta
        </a>
        <a [href]="signIn" target="_top" class="btn lg btn-white si-mg">
            Comenzar a Operar
        </a>
    </div>
</div>
