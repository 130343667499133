import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipDirective } from './tooltip.directive';

@NgModule({
    declarations: [TooltipDirective],
    imports: [CommonModule],
    exports: [TooltipDirective],
})
export class DirectivesModule {}
