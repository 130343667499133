<div class="landing-real-estate">
    <div class="header-content">
        <h1>Real Estate</h1>
    </div>
    <about-ci></about-ci>
    <why-real-estate>
        <chart-real-estate></chart-real-estate>
    </why-real-estate>
    <as-investment></as-investment>
    <faq [faq]="faq"></faq>
    <!--<legal></legal>-->
    <ct-footer></ct-footer>
</div>
