import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'icon-loading',
    templateUrl: './icon-loading.component.svg',
    styleUrls: ['./icon-loading.component.scss'],
})
export class IconLoadingComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
