<svg
    id="BarsIcon"
    class="bars-icon"
    data-name="BarsIcon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
>
    <defs>
        <clipPath id="clip-path">
            <circle class="bars-icon-1" cx="470" cy="2.36" r="150" />
        </clipPath>
    </defs>
    <g>
        <polygon
            class="bars-icon-2"
            points="298.78 282.03 298.78 296.59 1.45 296.59 1.45 282.03 20.39 282.03 20.39 266.65 32.27 266.65 32.27 282.03 69.66 282.03 69.66 236.71 81.54 236.71 81.54 282.03 117.82 282.03 117.82 261.72 129.7 261.72 129.7 282.03 168.81 282.03 168.81 244.16 180.69 244.16 180.69 282.03 217.19 282.03 217.19 215.34 229.45 215.34 229.45 282.03 267.96 282.03 267.96 181.27 279.64 181.27 279.64 282.03 298.78 282.03"
        />
        <polygon
            class="bars-icon-2"
            points="279.64 29.24 279.64 145.33 268.53 145.33 268.53 43.81 229.45 43.81 229.45 179.28 216.81 179.28 216.81 134.01 180.28 134.01 180.28 194.63 167.64 194.63 167.64 108.5 130.09 108.5 130.09 228.59 117.44 228.59 117.44 164.71 81.54 164.71 81.54 202.69 68.89 202.69 68.89 141.29 33.95 141.29 33.95 232.12 20.39 232.12 20.39 126.73 81.54 126.73 81.54 150.15 117.44 150.15 117.44 93.94 180.28 93.94 180.28 119.45 216.81 119.45 216.81 29.24 279.64 29.24"
        />
        <polygon
            class="bars-icon-2"
            points="301.45 156.34 301.45 171.72 265.27 171.72 240.79 202.32 204.21 202.32 153.95 252.58 110.72 252.58 110.72 252.51 110.63 252.58 90.69 227.13 65.21 227.13 41.94 256.82 1.45 256.82 1.45 241.97 35.16 241.97 57.12 213.94 98.11 213.94 116.75 237.73 146.4 237.73 197.18 186.94 233.35 186.94 257.84 156.34 301.45 156.34"
        />
    </g>
    <g class="bars-icon-3">
        <circle class="bars-icon-4" cx="153.47" cy="7.11" r="152.63" />
    </g>
</svg>
