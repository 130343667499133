<svg class="progress-circle" viewBox="0 0 100 100">
    <circle
        cx="60"
        cy="60"
        r="50"
        [attr.stroke-dasharray]="c"
        [attr.stroke-dashoffset]="0"
    ></circle>
    <circle
        cx="60"
        cy="60"
        r="50"
        [attr.stroke-dasharray]="c"
        [attr.stroke-dashoffset]="pct"
    ></circle>
</svg>
