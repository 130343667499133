<div class="video-project">
    <div class="title">Video</div>
    <div class="content-video">
        <iframe
            class="content-embed-video"
            [src]="url"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
    </div>
</div>
