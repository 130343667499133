import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'card-footer',
    templateUrl: './card-footer.component.html',
    styleUrls: ['./card-footer.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CardFooterComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
