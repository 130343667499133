<svg
    id="money-icon"
    class="money-icon"
    [class]="className"
    data-name="money-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
>
    <g>
        <rect
            class="money-icon-1"
            x="10.26"
            y="104.7"
            width="279.49"
            height="153.36"
        />
        <rect
            class="money-icon-2"
            x="31.03"
            y="73.95"
            width="235.38"
            height="14.4"
        />
        <rect
            class="money-icon-2"
            x="53.58"
            y="50.69"
            width="189.03"
            height="14.24"
        />
        <rect
            class="money-icon-2"
            x="83.03"
            y="173.58"
            width="14.51"
            height="14.66"
        />
        <rect
            class="money-icon-2"
            x="197.67"
            y="173.58"
            width="14.51"
            height="14.66"
        />
        <path
            class="money-icon-3"
            d="M171.09,210.17a23.56,23.56,0,0,1-3.52,6.92c-5.13,6.74-13.1,7.17-14.68,7.22v11.48h-8V224.36q-10.32-1.26-16-8.39t-5.66-19h14.54a45.68,45.68,0,0,0,.71,6.07c.45,2.43.68,3.65,1.25,4.55,2.26,3.55,7.76,3.57,9.05,3.58,2.14,0,5.77,0,8-2.62,1.81-2.15,1.69-4.92,1.58-7.31-.07-1.4,0-4.77-2.29-7.53-1.53-1.86-4.12-3.59-7.77-5.16a86.78,86.78,0,0,1-9.46-4.71,30.17,30.17,0,0,1-6.81-5.36,22.07,22.07,0,0,1-4.43-7.13c-1.06-2.73-1-17,.28-20a21.24,21.24,0,0,1,3.57-5.77,21.7,21.7,0,0,1,14.43-7.27V126h8.05v12.52a20.34,20.34,0,0,1,13.93,8.52q5,7.06,5,18.32l-12.93-.46a41.46,41.46,0,0,0-1.08-6.63c-.62-2.6-1-3.92-1.88-5-2.09-2.42-5.47-2.53-6.94-2.58-1.72-.05-5.3-.17-7.67,2.38s-2.05,6-1.84,8.58c.12,1.52.13,4.55,2.21,7q2.22,2.64,8.23,5.42a86.18,86.18,0,0,1,9.88,5.22,29.18,29.18,0,0,1,6.54,5.54,18.37,18.37,0,0,1,2.15,2.89,11.37,11.37,0,0,1,.68,1.29,36.77,36.77,0,0,1,1.28,4.23"
        />
        <rect
            class="money-icon-2"
            x="143.32"
            y="126.03"
            width="13.36"
            height="13.5"
        />
        <rect
            class="money-icon-2"
            x="142.03"
            y="222.29"
            width="13.36"
            height="13.5"
        />
    </g>
</svg>
