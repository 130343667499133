<div class="as-investment">
    <div class="row">
        <div class="col sm-12 md-6">
            <div class="content-text">
                <div class="title">¿Cómo invierto en Real Estate?</div>
                <p>
                    Hacelo desde la App o desde nuestra plataforma online en
                    tres pasos: elegís el proyecto, seleccionas la forma de pago
                    y en cuanto se acrediten los fondos tendrás tu inversión
                    activa en la plataforma.
                </p>
                <p>
                    Recordá que recién cuando se haya completado el fondeo del
                    proyecto te notificaremos tu porcentaje de participación en
                    el fideicomiso.
                </p>
            </div>
        </div>
        <div class="col sm-12 md-6 align-self-center">
            <img
                [src]="CustomizedServicesPlatform"
                alt="Movil con la Aplicación de Balaze"
            />
        </div>
    </div>
    <h1>¿Cómo es el Proceso?</h1>
    <timeline [data]="timelineData"></timeline>
</div>
