import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'navbar-right',
    templateUrl: './navbar-right.component.html',
    styleUrls: ['./navbar-right.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NavbarRightComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
