<div class="group-container">
    <div class="group-buttons">
        <button
            [class]="{ active: selectView === 'gallery' }"
            (click)="handleSelectView('gallery')"
        >
            galeria
        </button>
        <button
            [class]="{ active: selectView === 'map' }"
            (click)="handleSelectView('map')"
        >
            mapa
        </button>
    </div>
    <div class="group-content">
        <cp-gallery *ngIf="selectView === 'gallery'" [data]="data"></cp-gallery>
        <agm-map
            class="map-responsive"
            *ngIf="selectView === 'map'"
            [latitude]="lat"
            [longitude]="lng"
            [zoom]="15"
            [scrollwheel]="false"
        >
            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
        </agm-map>
    </div>
</div>
