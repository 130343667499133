import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'icon-sale',
    templateUrl: './icon-sale.component.html',
    styleUrls: ['./icon-sale.component.scss'],
})
export class IconSaleComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
