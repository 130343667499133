export const chartData = [
    { date: '1977', value: 320, variant: '' },
    { date: '1978', value: 500, variant: '56,3%' },
    { date: '1981', value: 1300, variant: '160,0%' },
    { date: '1983', value: 400, variant: '-69,2%' },
    { date: '1985', value: 380, variant: '-5,0%' },
    { date: '1987', value: 430, variant: '13,2%' },
    { date: '1989', value: 420, variant: '-2,3%' },
    { date: '1991', value: 900, variant: '114,3%' },
    { date: '1993', value: 1050, variant: '16,7%' },
    { date: '1995', value: 1100, variant: '4,8%' },
    { date: '1997', value: 1100, variant: '0,0%' },
    { date: '1999', value: 1100, variant: '0,0%' },
    { date: '2001', value: 1000, variant: '-9,1%' },
    { date: '2002', value: 600, variant: '-40,0%' },
    { date: '2003', value: 820, variant: '36,7%' },
    { date: '2004', value: 950, variant: '15,9%' },
    { date: '2005', value: 1000, variant: '5,3%' },
    { date: '2006', value: 1300, variant: '30,0%' },
    { date: '2007', value: 1350, variant: '3,8%' },
    { date: '2008', value: 1450, variant: '7,4%' },
    { date: '2009', value: 1470, variant: '1,4%' },
    { date: '2010', value: 1505, variant: '2,4%' },
    { date: '2011', value: 1630, variant: '8,3%' },
    { date: '2012', value: 1850, variant: '13,5%' },
    { date: '2013', value: 1800, variant: '-2,7%' },
    { date: '2014', value: 1693, variant: '-5,9%' },
    { date: '2015', value: 1817, variant: '7,3%' },
    { date: '2016', value: 2010, variant: '110,6%' },
    { date: '2017', value: 2142, variant: '6,6%' },
    { date: '2018', value: 2394, variant: '11,8%' },
    { date: '2019', value: 2366, variant: '-1,2%' },
    { date: '2020', value: 2097, variant: '-11,4%' },
];
