<div class="about-ci">
    <h2 class="title-about-ci">
        En Balanz ahora podés invertir en <strong>Propiedades,</strong> desde
        donde quieras, cuando quieras. Conocé cómo.
    </h2>
    <div class="row">
        <div class="col sm-12 md-6">
            <div class="content-text">
                <div class="title">¿Qué es el Crowdfunding Inmobiliario?</div>
                <p>
                    El crowdfunding es una red de financiamiento colectivo que,
                    a través de una plataforma digital, permite que se financie
                    un determinado proyecto gracias al aporte de varios
                    individuos.
                </p>
                <p>
                    Aplicado al Real Estate, permite adquirir propiedades entre
                    varios inversores quienes serán dueños de un porcentaje de
                    los activos y percibirán los retornos producidos por el
                    alquiler y/o revalorización de los bienes adquiridos.
                </p>
                <p>
                    Cada inversor se transforma en un fiduciante/beneficiario de
                    un fideicomiso ordinario privado por proyecto, estructurado
                    por
                    <strong> Crowdium,</strong> la primera plataforma de
                    crowdfunding Inmobiliario de Argentina y adminstrado por
                    <strong>CFA </strong>
                    <strong>(Compañía Fiduciaria Americana),</strong> una
                    fiduciaria pública registrada en la CNV.
                </p>
            </div>
        </div>
        <div class="col sm-12 md-6 align-self-center">
            <div class="content-video">
                <iframe
                    title="¿Qué es el Crowdfunding Inmobiliario?"
                    class="content-embed-video"
                    src="https://www.youtube.com/embed/7LmmMn5tgVs?origin=https://balanz.crowdium.com.ar&showinfo=0"
                ></iframe>
            </div>
        </div>
    </div>
    <div class="content-button">
        <cp-button
            [size]="'lg'"
            (click)="buttonCallToAction ? scrollSpy('#investments') : go()"
        >
            Ver Inversiones
        </cp-button>
    </div>
</div>
