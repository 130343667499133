<svg
    id="value-icon"
    [class]="className"
    data-name="value-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
>
    <path
        class="value-icon"
        d="M300,296.94H198V295a65.48,65.48,0,0,1-16,1.93H92.08a63.51,63.51,0,0,1-24-4.53,93.5,93.5,0,0,1-11.62-5.66v10.19H0V212.19H30.78a69.52,69.52,0,0,1,4-8.26L98.25,94.19V68.09c-8.64-6.88-32.2-27.17-31.81-43.29v-.31L66.69,19h.54A21.64,21.64,0,0,1,104,10a21.61,21.61,0,0,1,32.26.51,21.62,21.62,0,0,1,32.72,0A21.63,21.63,0,0,1,206.21,19h.37l.28,3.36a18.79,18.79,0,0,1,.15,2.4c0,.52,0,1-.07,1.54s-.11,1.06-.17,1.54l-.12.74c-.06.38-.15.76-.24,1.15s-.17.71-.27,1.06l-.17.58c-4.3,14.09-21.86,29.54-30.13,36.21V94.19l54.38,94.09h24.23v47.53H300ZM56.43,273a74.54,74.54,0,0,0,16,8.72,52.15,52.15,0,0,0,19.69,3.68H182A53.07,53.07,0,0,0,198,283V188.28h18.89L164.69,97.93H109.4L44.81,209.7c-.46.79-.91,1.61-1.35,2.49h13Zm198.66,10.92h33.39V273H255.09Zm-45.55,0h33.39V273H209.54Zm-198,0H44.91V273H11.52Zm243.57-24.19h33.39V248.82H255.09Zm-45.55,0h33.39V249.13H209.54Zm-198,0H44.91V249.13H11.52Zm198-23.94h33.39V225.22H209.54Zm-198,0H44.91V225.19H11.52Zm198-23.62h33.39V201.28H209.54ZM109.77,86.34h54.54V71H109.77ZM106,59.52h61.43a151.68,151.68,0,0,0,18.25-17.07c4.6-5.2,7.69-9.82,9-13.48l.39-1.43a10.14,10.14,0,1,0-19.48-5.63l-.35,1.23a8.53,8.53,0,0,0-.15,1.58c0,.23,0,.46,0,.68a5.45,5.45,0,0,1-5.61,5.08h-1a5.45,5.45,0,0,1-5.61-5.1c0-.22,0-.44,0-.66a9,9,0,0,0-.2-1.86l-.3-.95a10.14,10.14,0,0,0-19.48,0l-.36,1.23a9.46,9.46,0,0,0-.14,1.58c0,.22,0,.44,0,.66a5.45,5.45,0,0,1-5.61,5.1h-1a5.45,5.45,0,0,1-5.61-5.1c0-.22,0-.44,0-.66a9,9,0,0,0-.2-1.86l-.3-.95a10.15,10.15,0,0,0-19.89,2.81c0,.22,0,.44,0,.66a5.26,5.26,0,0,1-5.3,5.1h-.9a5.26,5.26,0,0,1-5.3-5.11c0-.21,0-.43,0-.65a10.15,10.15,0,1,0-19.88,2.82L79,29.77c1.47,3.52,4.44,7.85,8.71,12.67h0A151.77,151.77,0,0,0,106,59.52Z"
    />
    <g>
        <path
            class="value-icon"
            d="M138,249.64a51.57,51.57,0,1,1,51.57-51.56A51.62,51.62,0,0,1,138,249.64Zm0-93.91a42.35,42.35,0,1,0,42.35,42.35A42.39,42.39,0,0,0,138,155.73Z"
        />
        <path
            class="value-icon"
            d="M141.66,225.22H135v-4.83c-5.76-.74-9.54-2.58-13.26-6.41l-.82-.85,6.26-5.81.78.79c3.16,3.18,6.23,4.6,9.94,4.6,4.32,0,7.71-2.51,7.71-5.72a3.86,3.86,0,0,0-1.53-3,12.53,12.53,0,0,0-3.65-2l-6.07-2a26.69,26.69,0,0,1-8.26-4.48,9.86,9.86,0,0,1-3.3-7.87c0-6.83,4.54-11.54,12.2-12.76v-4h6.67v4c3.89.58,6.91,2,10.76,5.18l1,.85-6.38,5.83-.77-.65c-3.4-2.86-5.05-3.6-8.05-3.6-3.57,0-6,1.82-6,4.53a3.6,3.6,0,0,0,1.17,2.66c1.2,1,3,1.77,7.72,3.38,6.37,2.11,8,2.92,10.43,5.12a10.25,10.25,0,0,1,3.5,8.1c0,7.1-5,12.29-13.44,14Z"
        />
    </g>
</svg>
