<div class="porcentage-bar">
    <div class="porcentage-bar-title">{{ progress }}% Completado</div>
    <div class="bar">
        <div class="bar-progress" [style.width.%]="progress"></div>
    </div>
    <div class="porcentage-bar-min-max">
        <span>{{ min }}%</span>
        <span>{{ max }}%</span>
    </div>
</div>
