<div class="from-your-mobile">
    <div class="from-your-mobile-apps">
        <h1>Invertir ahora es más fácil con nuestras plataformas digitales</h1>
        <p>
            Balanz ofrece un amplio rango de productos de inversión e
            información oportuna para el manejo de sus activos financieros.
        </p>
        <a class="btn outline lg" [href]="callToAction" target="_top">
            Empezá a Invertir
        </a>
    </div>
    <div class="from-your-mobile-img">
        <img
            [src]="CustomizedServicesPlatform"
            alt="Movil con la Aplicación de Balaze"
        />
    </div>
</div>
