<div class="footer">
    <icon-logo [width]="180"></icon-logo>
    <p>
        Balanz Capital Valores S.A.U., CFA Cía Fiduciaria Americana S.A. y
        Crowdium S.R.L. no aseguran rendimientos ni rentabilidad de ningún tipo
        ni cuantía, ni la devolución del capital invertido. Los cálculos de
        rentabilidad estimada así como los plazos de inversión publicados son
        únicamente estimaciones realizadas por Crowdium S.R.L. en base a
        estudios de mercado internos. Las inversiones están sujetas a las
        fluctuaciones de precios y riesgos inherentes al mercado inmobiliario.
        Los potenciales inversores deberán realizar su propio análisis de riesgo
        previo a la inversión en los fideicomisos Crowdium. Balanz Capital
        Valores S.A.U., CFA Cía Fiduciaria Americana S.A. y Crowdium S.R.L. no
        prestan asesoramiento de ningún tipo a los inversores, que deberán
        consultar a sus propios asesores antes de realizar la inversión en los
        Fideicomisos Crowdium. "Se hace saber al público inversor que, conforme
        lo dispone el artículo 1673 del Código Civil y Comercial de la Nación,
        el registro de Fiduciarios Financieros no implica que los fideicomisos
        en los que estos actúen se encuentren sujetos al contralor de la
        COMISIÓN NACIONAL DE VALORES, cuya competencia sólo alcanza a los
        fideicomisos financieros emitidos bajo el régimen bajo el régimen de la
        oferta pública", CNV. El Producto ofrecido no califica como valor
        negociable, se considera que los fideicomisos no tienen autorización de
        oferta pública por la Comisión Nacional de Valores. Los mismos no son un
        instrumento que están bajo la regulación de la Comisiona Nacional de
        Valores, ni la ley 26.831.
    </p>
    <p>
        BALANZ CAPITAL VALORES S.A.U., Av. Corrientes 316 piso 3, C.A.B.A.
        INCRIPTO ANTE INSPECCION GENERAL DE JUSTICIA BAJO EL N° 13981 DEL LIBRO
        40, TOMO – SOCIEDAD POR ACCIONES CUIT: 30-71063067-0.
    </p>
</div>
