<svg
    id="investment"
    [class]="className"
    data-name="investment"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
>
    <g>
        <path
            class="investment-icon"
            d="M64.66,206.28H0V261H64.66ZM14,247.05V220.26h36.7v26.79Z"
        />
        <path
            class="investment-icon"
            d="M143.11,121.81H78.45V261h64.66ZM92.43,247.05V135.79h36.69V247.05Z"
        />
        <path
            class="investment-icon"
            d="M221.55,170.74H156.89V261h64.66Zm-50.68,76.31V184.72h36.7v62.33Z"
        />
        <path
            class="investment-icon"
            d="M235.34,93.71V261H300V93.71Zm14,153.34V107.69H286V247.05Z"
        />
        <rect class="investment-icon" y="270.94" width="300" height="13.98" />
        <path
            class="investment-icon"
            d="M267.67,15.08a25.48,25.48,0,0,0-21.83,38.7l-45,48.28a25.31,25.31,0,0,0-23.22,0l-45-48.28a25.53,25.53,0,1,0-43.66,0l-45,48.28a25.65,25.65,0,1,0,10.22,9.54l45-48.28a25.33,25.33,0,0,0,23.23,0l45,48.28a25.53,25.53,0,1,0,43.66,0l45-48.28a25.52,25.52,0,1,0,11.61-48.24ZM43.88,124.77a11.55,11.55,0,1,1-11.55-11.55A11.56,11.56,0,0,1,43.88,124.77Zm145.34,11.54a11.55,11.55,0,1,1,11.55-11.54A11.57,11.57,0,0,1,189.22,136.31Zm-66.89-95.7a11.55,11.55,0,1,1-11.55-11.55A11.57,11.57,0,0,1,122.33,40.61Zm156.89,0a11.55,11.55,0,1,1-11.55-11.55A11.56,11.56,0,0,1,279.22,40.61Z"
        />
    </g>
</svg>
